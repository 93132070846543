<template>
  <fragment>
    <template v-if="value">
      <v-layout align-center justify-center fill-height>
        <v-progress-circular indeterminate color="primary"/>
      </v-layout>
    </template>
    <template v-else>
      <slot/>
    </template>
  </fragment>
</template>

<script>
export default {
  props: {
    value: Boolean
  }
}
</script>
